<template>
  <div>
    <div class="wfill vertical flex">
      <button @click="showMailPopup" class="mlauto black mt16 mr20">
        Send Mail
      </button>
    </div>
    <h4 class="ml40">{{filteredUsers.length}} inactive users</h4>
    <div  class="ml40" v-if="users.length == 0">Loading...</div>
    <div class="mt12 px40 flex">
      
      <div class="vertical flex">
        <label for="" style="color: transparent">Search</label>
        <div style="border: 1px solid #ccc; padding: 0 12px;">
          <i class="ml8 search icon"></i>
          <input placeholder="Search" type="text" class="search_input" v-model="filter.search">
        </div>
      </div>
      <div class="ml12 vertical flex">
        <label for="">Type</label>
        <select v-model="filter.type">
          <option value="" selected>Type</option>
          <option value="Student">Student</option>
          <option value="Professional">Professional</option>
          <option value="Faculty">Faculty</option>
        </select>
      </div>
      <div class="ml12 vertical flex">
        <label for="">Field</label>
        <select name="" id="" v-model="filter.field">
          <option value="" selected>Field</option>
          <option v-for="f in fields" :key="f" :value="f">{{f}}</option>
        </select>
      </div>
    </div>
    <table class="ui celled table" style="width: calc(100% - 40px); margin: 20px;">
      <thead>
        <tr>
          <th>Name</th>
          <th>Field</th>
          <th>Type</th>
          <th>Info</th>
        </tr>
      </thead>
      <tr v-for="u of filteredUsers" :key="u.id">
        <td>{{u.name}}</td>
        <td>{{u.field}}</td>
        <td>{{u.type}}</td>
        <td>
          <p>{{u.email}}</p>
          <p>{{u.institute}}</p>
        </td>
      </tr>
    </table>
    <mail-popup :emails="filteredUsers" ref="mailPopup" />
  </div>
</template>

<script>
import mailPopup from '../components/mail-popup'
export default {
  components: { mailPopup },
  name: 'mass-mail',
  data () {
    return {
      users: [],
      migrated: [],
      fields: ['Architecture', 'Interior Design', 'Civil Engineering', 'Planning', 'Design', 'Other'],
      inactiveUserCount: 0,
      filter: {
        search: '',
        region: '',
        field: '',
        type: ''
      },
    }
  },
  computed: {
    filteredUsers: function () {
      let users = [...this.users]
      if (this.filter.search) {
        users = users.filter(u => {
          if (u.username)
          u.username.toLowerCase().includes(this.filter.search.toLowerCase())
        })
      }
      if (this.filter.field) {
        users = users.filter(u => u.field == this.filter.field)
      }
      if (this.filter.type) {
        users = users.filter(u => u.type == this.filter.type)
      }
      return users
    }
  },
  methods: {
    showMailPopup: function () {
      let mailPopup = this.$refs.mailPopup
      mailPopup.active = !mailPopup.active
    }
  }
}
</script>

<style lang="css">
  .search_input {
    height: 34px;
    font-size: 12px;
    border: none;
  }
  .search_input:focus {
    outline: none;
  }
</style>